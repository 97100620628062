import styled from 'styled-components'
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import "@babel/polyfill";

import Layout from "../layouts"
import Head from "../components/head"
import ImageHero from "../components/image-hero"
import CopyLeft from "../components/copyleft"
import CopyRight from "../components/copyright"
import ThreeColumn from "../components/three-column"
import TestimonialSlider from '../components/testimonial-slider';

import YoastSeoParts from '../fragments/seo';

class AboutUs extends Component {

  render() {
    const page = this.props.data.wpPage;
    const { seo } = page
    const { templateAboutUs: { pageAccentColor }, hero, copyRight, threeColumn, copyLeft, copyRight2, awardsSlider, testimonials } = this.props.data.wpPage.template;

    return (
      <Layout>
        <PageWrapper accentColor={pageAccentColor || '#fe6a00'}>
          <Head title={seo.title} description={seo.metaDesc} meta={seo} />
          <ImageHero image={this.props.data.wpPage.heroImage} title={hero.heroHeadline} copy={hero.heroCopy} />
          <div className="about-us-intro-container">
            <CopyRight image={copyRight.imageLeftImage} title={copyRight.imageLeftTitle} copy={copyRight.imageLeftCopy} cta={copyRight.imageLeftCta} />
          </div>
          <ThreeColumn left={threeColumn.left3Column} middle={threeColumn.middle3Column} right={threeColumn.right3Column} />
          {!testimonials.hideTestimonialSlider &&
            <TestimonialSlider testimonials={testimonials.testimonialSlider} />
          }
          <CopyLeft image={copyLeft.imageRightImage} title={copyLeft.imageRightTitle} copy={copyLeft.imageRightCopy} cta={copyRight.imageRightCta} />
        </PageWrapper>
      </Layout>
    )
  }
}

const PageWrapper = styled.div`
  .defaultHero .dots span {
    color: ${({ accentColor }) => accentColor};
  }

  .defaultHero {
    border-top-color: ${({ accentColor }) => accentColor};
  }

  hr {
    border-color: ${({ accentColor }) => accentColor} !important;
  }

  .maincopy h3 {
    font-size: 24px;
  }

  .container.copyLeft {
    background-color: #e1e8ee;
  }

  .about-us-intro-container .container.copyRight {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 70%, rgba(225,232,238,1) 100%);
  }
`

export default AboutUs

AboutUs.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const query = graphql`
  query($id: String!) {
    wpPage(id: {eq: $id}) {
      id
      uri
      link
      title
      seo {
        ...seoParts
      }
      template {
        ... on WpTemplate_AboutUs {
          templateAboutUs {
            pageAccentColor
          }

          hero:templateAboutUs {
            heroHeadline
            heroCopy
          }
          copyRight : templateAboutUs {
            imageLeftImage {
              altText
              localFile {
                publicURL
              }
            }
            imageLeftCopy
            imageLeftTitle
            imageLeftCta {
              url
              title
              target
            }
          }
          threeColumn: templateAboutUs {
            left3Column {
              image {
                id
                altText
                localFile {
                  publicURL
                }
              }
              title
              copy
            }
            middle3Column {
              image {
                id
                altText
                localFile {
                  publicURL
                }
              }
              title
              copy
            }
            right3Column {
              image {
                id
                altText
                localFile {
                  publicURL
                }
              }
              title
              copy
            }
          }
          copyLeft : templateAboutUs {
            imageRightImage {
              altText
              localFile {
                publicURL
              }
            }
            imageRightCopy
            imageRightTitle
            imageRightCta {
              url
              title
              target
            }
          }
          copyRight2 : templateAboutUs {
            imageLeft2Image {
              altText
              localFile {
                publicURL
              }
            }
            imageLeft2Copy
            imageLeft2Title
            imageLeft2Cta {
              url
              title
              target
            }
          }
          awardsSlider: templateAboutUs {
            awards {
              id
              altText
              localFile {
                publicURL
              }
            }
          }
          testimonials: templateAboutUs {
            hideTestimonialSlider
            testimonialSlider {
              ... on WpTestimonial {
                id
                title
                cptSingleTestimonial {
                  firstName
                  lastName
                  position
                  testimonial
                }
              }
            }
          }
        }
      }
      heroImage: featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`