import React from "react"
import { graphql } from "gatsby"

import '../css/three-column.css';

import image1 from '../images/image1.png';

const ThreeColumn = ({ left, middle, right }) => {

  return (
    <div className="container threeColumnContainer">
      <div className="grid smallGrid">
        <div className="col-sm-4 maincopy threeColumn">
          { left.image && 
            <img src={left.image.localFile.publicURL} className="flex-img" alt={left.altText} />
          }
          <h3>{left.title}</h3>
          <p>{left.copy}</p>
        </div>
        <div className="col-sm-4 maincopy threeColumn">
          { middle.image && 
            <img src={middle.image.localFile.publicURL} className="flex-img" alt={middle.altText} />
          }
          <h3>{middle.title}</h3>
          <p>{middle.copy}</p>
        </div>
        <div className="col-sm-4 maincopy threeColumn">
          { right.image && 
            <img src={right.image.localFile.publicURL} className="flex-img" alt={right.altText} />
          }
          <h3>{right.title}</h3>
          <p>{right.copy}</p>
        </div>
      </div>
    </div>
  )
}

export default ThreeColumn